import React, { Suspense } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// components
const Home = React.lazy(() => import('./components/Home'));
const Faqs = React.lazy(() => import('./components/Faqs'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./components/TermsAndConditions'));

function App() {
  return (
    <div className="App">
      <Suspense>
        <Routes>
          <Route element={<AppLayout />} >
            <Route path='/' element={<Home />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='*' element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </div >
  );
}

export default App;
